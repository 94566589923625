import React from 'react'
import Layout from '../components/Layout'
import styled from 'styled-components'
import SEO from '../components/seo'
import vbjHero from '../images/vbjHero.png'
import vbj_desktop1 from '../images/vbj_desktop1.png'
import vbj_desktop2 from '../images/vbj_desktop2.png'
import vbj_desktop3 from '../images/vbj_desktop3.png'
import vbj_mobile1 from '../images/vbj_mobile1.png'
import vbj_mobile2 from '../images/vbj_mobile2.png'
import vbj_mobile3 from '../images/vbj_mobile3.png'
import vbj_jewel1 from '../images/vbj_jewel1.png'
import vbj_jewel2 from '../images/vbj_jewel2.png'
import vbj_jewel3 from '../images/vbj_jewel3.png'
import vbj_jewel4 from '../images/vbj_jewel4.png'
import Link from "../components/Link";

export default function vbj() {
    return (
        <StyledWrapper>
            <Layout>
                <SEO title="VBJ Case Study"
                     description="With the pandemic, the jewellery sector in India geared up for competitive digital commerce experiences, Vummidi Bangaru Jewellers got a headstart."
                     pageImage={vbjHero} path="/vbj"/>

                <div className="case__study">
                    <div className="case__study__main">
                        <div className="container">

                            <div className="case__study__herotext text-left">
                                <h5>CASE STUDY</h5>
                                <hr className="case__study__line"/>
                                <div className="case__study__title">
                                    <h5>Vummidi Bangaru Jewellers</h5>
                                    <h6 className="case__study__page__title d-sm-block d-none">
                                        Modernizing a business with legacy
                                    </h6>
                                </div>
                            </div>

                            <img src={vbjHero} className="hero-image-desktop" alt="vbj hero image"/>

                            <div className="case__study__page__titleMobile d-sm-none d-block pb-4">
                                Modernizing a business with legacy
                            </div>

                            <div className="row case__study__details">
                                <div className="col-sm-8 col-12">
                                    <p className="info__desc">With the pandemic, the jewellery sector in India geared up
                                        for competitive digital
                                        commerce experiences, Vummidi Bangaru Jewellers got a headstart.
                                    </p>
                                    <div className="case__study__website__links">
                                        <Link to="https://www.vummidi.com">VIEW WEBSITE</Link>
                                    </div>
                                </div>
                                <div className="case__study__tools col-sm-4 col-12">
                                <span>
                                   <h4>What We Did:</h4>
                                   <h5>UX, Visual Design & Development, Video, Photography</h5>
                                </span>
                                    <span>
                                   <h4>Built With:</h4>
                                   <h5>Shopify, React</h5>
                                </span>
                                    <span>
                                   <h4>Photography/Video:</h4>
                                   <h5>Gokul Rao Kadam, Maran R</h5>
                                </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="vbj__desktopImg py-5">
                        <div className="container">
                            <div className="row d-flex align-items-center">
                                <div className="col-sm-6 col-12">
                                    <div className="desktop__image flex-direction-column">
                                        <img src={vbj_desktop1} alt="vbj desktop Image1"/>
                                        <img src={vbj_desktop2} alt="vbj desktop Image2"/>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-12">
                                    <div className="desktop__image">
                                        <img src={vbj_desktop3} alt="vbj desktop Image3"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="vbj__mobileImg py-5">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-4 col-12">
                                    <img src={vbj_mobile1} className="mobile__image" alt="vbj mobile Image1"/>
                                </div>
                                <div className="col-sm-4 col-12">
                                    <img src={vbj_mobile2} className="mobile__image" alt="vbj mobile Image2"/>
                                </div>
                                <div className="col-sm-4 col-12">
                                    <img src={vbj_mobile3} className="mobile__image" alt="vbj mobile Image3"/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="vbj__jewel py-5">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-4 col-12">
                                    <img src={vbj_jewel1} className="jewel__image" alt="vbj jewellary Image1"/>
                                </div>
                                <div className="col-sm-8 col-12">
                                    <img src={vbj_jewel2} className="jewel__image" alt="vbj jewellary Image2"/>
                                </div>
                            </div>
                            <img src={vbj_jewel3} className="jewel__image" alt="vbj jewellary Image3"/>
                            <img src={vbj_jewel4} className="jewel__image" alt="vbj jewellary Image4"/>
                        </div>
                    </div>

                </div>
            </Layout>
        </StyledWrapper>
    )
}

const StyledWrapper = styled.div`

  .case__study__main {
    background: #433C2F;
    color: #FFFFFF;
  }

  .case__study__line {
    border-color: white;
  }

  .case__study__website__links > a {
    color: #FFFFFF !important;
  }

  .hero-image-desktop {
    width: 100%;
    object-fit: cover;
    padding: 50px 0px;
  }

  .desktop__image > img {
    width: 100%;
    object-fit: cover;
    padding-bottom: 30px;
  }

  .vbj__mobileImg {
    background: #CCC2AF;
  }

  .mobile__image {
    width: 100%;
    object-fit: cover;
    padding-bottom: 30px;
  }

  .jewel__image {
    width: 100%;
    object-fit: cover;
    padding-bottom: 30px;
  }


  @media (min-width: 992px) {

    .hero-image-desktop {
      padding-top: 120px;
    }

    .mobile__image {
      width: 100%;
      object-fit: cover;
      padding: 20px;
    }

    .jewel__image {
      width: 100%;
      object-fit: cover;
      padding: 20px;
      height: 100%;
    }

  }
`