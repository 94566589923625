import React from 'react';
import Menu from './Menu';
import styled from 'styled-components';
import "./layout.css";
import firstMainLogo from '../images/firstMainLogo.svg';
import firstMainWhiteLogo from '../images/firstMainWhiteLogo.svg';
import Footer from './Footer'
import {Link} from 'gatsby';
import { StaticImage } from "gatsby-plugin-image";

const layout = ({children}) => {

    //checks if path is defined before assigning it
    const location = children[0]?.props?.path ? children[0].props.path : "";
    // const firstMainLogo = '../images/firstMainLogo.svg';
    // const firstMainWhiteLogo = '../images/firstMainWhiteLogo.svg';


    let logo = location === '/vbj' ? firstMainWhiteLogo : firstMainLogo;
    console.log("logo location = ", logo)
    return (
        <StyledWrapper>

            <div className="nav-border"></div>
            <div id="page-wrap">
                <div className="margin-container">
                    <Menu/>
                    <div className="logo-container container-fluid">
                        <Link to="/">
                            <img className="main__logo" src={logo} alt="1st Main Logo"/>
                        </Link>
                    </div>
                    <div>
                        {children}
                    </div>
                    <Footer/>
                </div>
            </div>

        </StyledWrapper>
    )
}

export default layout;

const StyledWrapper = styled.div`
  .page__title {
    padding-bottom: 50px;
    font-size: 48px;
    font-weight: 400;
    line-height: 48px;
  }

  .logo-container > a::before {
    visibility: hidden;
  }

  .main__logo {
    position: absolute;
    left: 30px;
    top: 12px;
    height: 44px;
  }

  .page__headings {
    font-size: 24px;
  }

  .page__title {
    font-size: 36px;
  }

  @media (min-width: 576px) {
    .main__logo {
      left: 20px;
      top: 13px;
      height: 44px;
    }
  }

  @media (min-width: 768px) {
    .main__logo {
      left: 35px;
      top: 24px;
      height: 44px;
    }
  }

  @media (min-width: 992px) {
    .main__logo {
      top: 15px;
      height: 62px;
    }
  }

`